.selectBox {
  position: relative;

  .selectVal {
    padding-left: 12px;
    margin-left: 6px;
    border-left: 1px solid #93aebc;

    :hover {
      cursor: pointer;
    }

    .DownIcon {
      width: 20px;
      height: 20px;
    }

    .select-caret {
      transition: transform 0.3s;
      transform: rotate(180deg);
      cursor: pointer;
    }

    .MuiTypography-h4 {
      font-size: 1.1rem !important;
    }
  }

  .selectList {
    display: none;
    position: absolute;
    top: 40px;
    z-index: 999;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 12px 0;
    left: 4px;

    .selectItem {
      padding: 6px 16px;

      :hover {
        cursor: pointer;
      }
    }
  }

  .selectListDark {
    // background-color: #242426 !important;
    background: linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%) !important;
    box-shadow: 14px 14px 100px 0px rgba(0, 0, 0, 0.40) !important;
  }
}

.show {
  display: block !important;
}
