#locales-popper {
  z-index: 1101;
  background-color: #00000003;
  margin-top: 20px !important;
  border-radius: 10px;
  .MuiPaper-root.locales-menu {
    border-radius: 50px;

    border: 2.4px solid rgba(28, 30, 33, 0.6);
    background: linear-gradient(136deg, #363b40 9.64%, #1d1e23 89.75%);
    box-shadow: 2px 4px 9px 0px #070707, -3px -2px 16px 0px #455059;
    // padding: 6px 6px;
    // display: flex;
    // flex-direction: column;
    // width: 80px;
    // max-width: 80px;
    & > div {
      // min-width
      border-radius: 50px;

      background: linear-gradient(136deg, #32373a 9.64%, #131416 89.75%);
      box-shadow: 0px 0px 4px 0px #33383c inset;

      // width: 42px;
      // display: block;
      width: 100%;
      text-align: center;
      // margin-bottom: 4px;
      padding: 6px;
    }
  }
}
