#swap-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: "Garamond";

  // .ido-container {
  //   width: 80%;
  // }
  .ido-grid {
    //     border: 1px solid rgba(252, 252, 252, 0.3);
    // box-sizing: border-box;
    position: relative;
    border-radius: 20px;
    height: 100px;
    padding: 8px 20px;

    &:nth-child(2) {
      margin-bottom: 10px;
    }

    &:nth-child(3) {
      margin-bottom: 12px;
    }
  }

  .swap-input {
    color: #fff;
    text-align: right;
    padding: 0;
    font-size: 1rem;
    padding-right: 2px;
  }

  .approve-button {
    min-height: 46px;
    width: 100%;
  }

  .center-right {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 28px);
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .ido-grid-btn {
    margin-bottom: 20px;
  }

  .swap-header {
    position: relative;
    width: 100%;
    margin-bottom: 10px;

    .setting-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .note-container {
    width: 550px;
    padding: 0 18px;

    a {
      color: #00a664;
      text-decoration: none;
      padding: 0 5px;
    }
  }

  .MuiInputBase-input {
    text-align: right;
  }

  .swap-tip {
    padding: 12px;
  }

  .icon-down {
    margin-left: 10px;
  }
}

@media screen and (max-width: 580px) {
  #swap-view {
    .approve-button {
      min-height: 32px;
    }
  }

  .ido-card {
    width: 95% !important;
  }

  .note-container {
    width: 95% !important;
    padding: 0 8px !important;
  }
}
