#hades {
  // position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 10000;

  // background-color: #55555555;
  .ohm-popover {
    max-width: 500px;
    display: flex;
    flex-direction: column;

    h3 {
      align-self: center;
      justify-self: center;
      width: 75%;
      text-align: center;
    }

    label {
      margin: 33px 0 10px;
    }

    .help-text {
      margin: 10px;
    }
  }

  .mobile .ohm-popover {
    max-width: 400px;
  }

  #slippage {
    // height: 40px;
    padding: 0 10px;
    font-family: Square;
    font-weight: 500;
    font-size: 20px;
  }

  #recipient {
    font-family: Square;
    font-weight: 500;
    font-size: 20px;
  }

  .slippage-item {
    padding: 0 16px;
    height: 40px;
    line-height: 1;
    font-size: 20px;
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid rgba(252, 252, 252, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 12px;
  }
}

@media screen and (max-width: 480px) {
  .slippage-container {
    // flex-wrap: wrap;
    // justify-content: flex-start !important;
  }

  #hades .slippage-item {
    margin-bottom: 10px !important;
    height: 25px !important;
  }

  .slippage-ipt {
    margin-bottom: 10px !important;
    border-radius: 8px !important;
    height: 25px !important;
  }

  .recipient-ipt {
    border-radius: 8px !important;
    height: 25px !important;
  }

  .last-icon {
    display: flex !important;
  }

  .slippage-precent {
    display: none !important;
  }
}

@media screen and (min-width: 480.1px) {
  .last-icon {
    display: none !important;
  }
}
