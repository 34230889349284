#hades {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  .ohm-popover {
    max-width: 500px;
    display: flex;
    flex-direction: column;

    // padding: 10px 0;
    h3 {
      align-self: center;
      justify-self: center;
      width: 75%;
      text-align: center;
    }

    label {
      margin: 33px 0 10px;
    }

    .help-text {
      margin: 10px;
    }
  }

  .mobile .ohm-popover {
    max-width: 400px;
  }

  .card-content {
    padding: 0 20px;
  }

  .token-item {
    cursor: pointer;
    padding: 10px 0;
    margin: 5px 0;
  }
}
