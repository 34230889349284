.btn-footer {
  // background: rgba(54, 201, 139, 0.3) !important;
  // background: transparent !important;
  // border-radius: 8px;
  padding: 10px 20px;
  // border: 1px solid !important;
  // border-image: -webkit-linear-gradient(90.1deg, #31c98d 0.07%, #c9c331 100.95%) 1 10 round !important;
  // border-image: -moz-linear-gradient(90.1deg, #31c98d 0.07%, #c9c331 100.95%) 1 10 round !important;
  // border-image: linear-gradient(90.1deg, #31c98d 0.07%, #c9c331 100.95%) 1 10 round;
  // position: relative;
  // border: 1px transparent solid !important;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: -3px;
  //   right: -3px;
  //   top: -3px;
  //   bottom: -3px;
  //   background: linear-gradient(90.1deg, #31c98d 0.07%, #c9c331 100.95%);
  //   border-radius: 8px;
  //   content: "";
  //   z-index: -1;
  // }
  // .btn-content {
  //   background-image: -webkit-linear-gradient(right, rgba(201, 195, 49, 1), rgba(49, 201, 141, 1));
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  // }
  // border: solid 10px transparent;
  // border-radius: 8px;
  // background-image: linear-gradient(rgba(54, 201, 139, 0.3), rgba(54, 201, 139, 0.3)),
  //   linear-gradient(90.1deg, #31c98d 0.07%, #c9c331 100.95%);
  // background-origin: border-box;
  // background-clip: content-box, border-box;
  // width: 200px;
  // height: 100px;
  border: 2px solid !important;
  border-image-source: linear-gradient(90.1deg, #31c98d 0.07%, #c9c331 100.95%) !important;
  clip-path: inset(0 round 4px) !important;
  background-color: rgba(54, 201, 139, 0.3) !important;
  border-image-slice: 1 !important;
  border-image-repeat: stretch !important;
  // border-radius: 10px ;
}
